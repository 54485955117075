import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import AvatarMUI from '@mui/material/Avatar';
import {
  Badge,
  Button,
  Dialog,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const AvatarMUIWithMotion = motion(AvatarMUI);

const Avatar = ({ size, img, prefix, name, userId, type, label }) => {
  const businessPreference = useSelector((state) => state.core.businessData);
  const employees = useSelector((state) => state.core.employees);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [openedFile, setOpenedFile] = useState(null);

  const { t } = useTranslation();
  const currentEmployee = employees?.employees?.find(
    (employee) => employee?.id === userId
  );

  function stringToColor(name) {
    const mainColor = businessPreference?.mainColor || '#000'; // Default to '#000' if undefined
    const baseColor = chroma.valid(mainColor) ? mainColor : '#000';
    const colors = chroma.scale(['black', baseColor]).mode('lch').colors(19);

    let hash = 0;
    if (name) {
      // Check if name is not undefined
      for (let i = 0; i < name?.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
    }
    const index = Math.abs(hash) % colors?.length;
    return colors[index];
  }

  const handleOpen = (url, mimeType) => {
    setOpenedFile({ url, mimeType });
    setOpen(true);
  };

  const handleClose = () => {
    setOpenedFile(null);
    setOpen(false);
  };

  const getImgData = async (imagePath) => {
    try {
      const imgData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `getProtectedFile`,
        body: {
          filePath: imagePath,
        },
        options: {
          responseType: 'blob',
        },
      });
      const mimeType = imgData.type;

      // Create an object URL from the Blob
      const objectUrl = URL.createObjectURL(imgData);

      // Return both the URL and MIME type
      return { objectUrl, mimeType };
    } catch (error) {
      console.error('Error fetching image data:', error);
    }
  };

  function stringAvatar(name) {
    if (!name) return {}; // Return an empty object if name is undefined
    const color = stringToColor(name);
    return {
      sx: {
        bgcolor: color,
        backgroundImage: `radial-gradient(circle, ${chroma(color).brighten(
          0.5
        )} 0%, ${color} 30%, ${chroma(color).darken(0.7)} 100%)`,
        maxWidth: `${size === 'xsm' ? '20px' : '30px !important'}`,
        maxHeight: `${size === 'xsm' ? '20px' : '30px !important'}`,
        fontSize: `${size === 'xsm' ? '0.6rem' : '0.8rem !important'}`,
        borderRadius: '50%',
      },
      children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
    };
  }

  useEffect(() => {
    let isMounted = true;

    const fetchFiles = async () => {
      const fileArray = Array.isArray(img) ? img : [img];

      const fileDataArray = await Promise.all(
        fileArray.map(async (image) => {
          if (
            type === 'media-single-private' &&
            typeof image === 'string' &&
            image.startsWith('http')
          ) {
            return await getImgData(image || image?.fileUrl);
          } else if (image) {
            return {
              objectUrl: image || image?.fileUrl,
              mimeType: null,
            };
          }
          return null;
        })
      );

      if (isMounted) {
        setFiles(fileDataArray.filter(Boolean));
      }
    };

    fetchFiles();

    return () => {
      isMounted = false;
      files.forEach((file) => {
        if (file?.objectUrl && file.objectUrl.startsWith('blob:')) {
          URL.revokeObjectURL(file.objectUrl);
        }
      });
    };
  }, [img, type]);

  const prefixColor = 'transparent';

  return (
    <>
      {open && openedFile && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <div className="align-c px-5 py-5">
            {openedFile?.mimeType &&
            openedFile.mimeType.startsWith('image/') ? (
              <img
                src={openedFile?.url}
                alt={name}
                style={{
                  width: 'auto',
                  height: '70vh',
                  border: 'none',
                  borderRadius: '10px',
                }}
              />
            ) : openedFile.mimeType === 'application/pdf' ? (
              <iframe
                src={openedFile?.url}
                title="PDF"
                style={{
                  width: '100%',
                  height: '70vh',
                  border: 'none',
                  borderRadius: '10px',
                }}
              />
            ) : openedFile.mimeType &&
              openedFile.mimeType.startsWith('text/') ? (
              <iframe
                src={openedFile?.url}
                title="Text"
                style={{
                  width: '100%',
                  height: '70vh',
                  border: 'none',
                  borderRadius: '10px',
                }}
              />
            ) : (
              <div>
                <Typography variant="body1">
                  File preview is not available.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = openedFile.url;
                    link.download = `file.${
                      openedFile.mimeType?.split('/')[1] || 'txt'
                    }`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  {t('download')}
                </Button>
              </div>
            )}
          </div>
        </Dialog>
      )}

      <div>
        {img === 103120 || img === '103120' || img === undefined ? (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <AvatarMUIWithMotion
              {...stringAvatar(name || 'N D')}
              whileHover={{ scale: 1.05, rotateX: 5, rotateY: 5, rotateZ: 5 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
          </div>
        ) : (
          <>
            <Tooltip title={name || ''}>
              {files.length > 0 ? (
                files.map((file, index) => {
                  const { objectUrl, mimeType } = file;

                  // Determine the file type
                  const fileType = mimeType ? mimeType.split('/')[0] : null;
                  const fileExtension = mimeType
                    ? mimeType.split('/')[1]
                    : null;

                  const endFileType = mimeType ? mimeType.split('/')[1] : null;

                  let content;
                  if (fileType === 'image' || endFileType === 'image') {
                    content = (
                      <AvatarMUI
                        key={index}
                        onClick={() => handleOpen(objectUrl, mimeType)}
                        sx={{
                          bgcolor: `${prefixColor}20`,
                          borderRadius:
                            size === 'large' ? '10px !important' : '50%',
                          maxWidth: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                          maxHeight: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                        }}
                        src={
                          objectUrl || '/assets/v3/img/empty-state-light.png'
                        }
                        alt={name}
                      />
                    );
                  } else if (fileExtension === 'pdf' || endFileType === 'pdf') {
                    content = (
                      <AvatarMUI
                        key={index}
                        onClick={() => handleOpen(objectUrl, mimeType)}
                        sx={{
                          bgcolor: `${prefixColor}20`,
                          borderRadius:
                            size === 'large' ? '10px !important' : '50%',
                          maxWidth: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                          maxHeight: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                        }}
                        alt={name}
                      >
                        <PictureAsPdfIcon />
                      </AvatarMUI>
                    );
                  } else if (fileType === 'text' || endFileType === 'text') {
                    content = (
                      <AvatarMUI
                        key={index}
                        onClick={() => handleOpen(objectUrl, mimeType)}
                        sx={{
                          bgcolor: `${prefixColor}20`,
                          borderRadius:
                            size === 'large' ? '10px !important' : '50%',
                          maxWidth: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                          maxHeight: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                        }}
                      >
                        <DescriptionIcon />
                      </AvatarMUI>
                    );
                  } else {
                    content = (
                      <AvatarMUI
                        key={index}
                        onClick={() => handleOpen(objectUrl, mimeType)}
                        sx={{
                          bgcolor: `${prefixColor}20`,
                          borderRadius:
                            size === 'large' ? '10px !important' : '50%',
                          maxWidth: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                          maxHeight: `${
                            size === 'xsm'
                              ? '20px'
                              : size === 'large'
                              ? '50px !important'
                              : '30px !important'
                          }`,
                        }}
                      >
                        <InsertDriveFileIcon />
                      </AvatarMUI>
                    );
                  }
                  return content;
                })
              ) : (
                <AvatarMUI
                  sx={{
                    bgcolor: `${prefixColor}20`,
                    borderRadius: size === 'large' ? '10px !important' : '50%',
                    maxWidth: `${
                      size === 'xsm'
                        ? '20px'
                        : size === 'large'
                        ? '50px !important'
                        : '30px !important'
                    }`,
                    maxHeight: `${
                      size === 'xsm'
                        ? '20px'
                        : size === 'large'
                        ? '50px !important'
                        : '30px !important'
                    }`,
                  }}
                  src={'/assets/v3/img/empty-state-light.png'}
                  alt={name}
                />
              )}
            </Tooltip>
            {label && (
              <div style={{ marginBottom: '2px' }}>
                <Typography
                  fontSize="8.5px"
                  sx={{ marginTop: '-20px' }}
                  fontWeight={300}
                  color="#69696980"
                  variant="caption"
                >
                  {label}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Avatar;
